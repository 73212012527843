<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../../assets/1.png" alt="" />
      </div>
      <!-- 登录表单区域 -->
      <el-form v-loading="loading" ref="loginFormRef" :rules="loginFormRules" :model="loginForm" label-width="0px" class="login_form">
        <!-- ユーザー名 -->
        <el-form-item prop="mobile">
          <el-input v-model="loginForm.mobile" prefix-icon="el-icon-user-solid" placeholder="ユーザー名を入力してください" maxlength="11"> </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" :type="flag ? 'password' : 'text'" placeholder="パスワードを入力してください">
            <i slot="suffix" :class="[flag ? 'el-icon-minus' : 'el-icon-view']" style="margin-top:8px;font-size:18px;" autocomplete="auto" @click="flag = !flag" />
          </el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item style="width: 250px;float: left;margin-bottom: 10px;">
          <el-input @keyup.enter.native="handleLogin" v-model="inputInfo" prefix-icon="el-icon-lock" type="text" placeholder="キャプチャーを入力してください"></el-input>
        </el-form-item>
        <el-form-item style="width: 200px;float: right;margin-bottom: 10px;margin-top: 2px;">
          <SIdentify :identifyCode="identifyCode" style="float: left;"></SIdentify>
          <el-link :underline="false" style="float: left;" @click="changeCode">再表示</el-link>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button id="btn" type="primary" @click="handleLogin" @keyup.enter.native="handleLogin" v-preventReClick="3000">ログイン</el-button>
          <el-button type="info" @click="resetLoginForm">リセット</el-button>
        </el-form-item>
      </el-form>
      <el-form style="margin-top: 80%;">
        <el-form-item class="btns">
          
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import SIdentify from '../Home/Identify'
import md5 from 'js-md5'
import loginService from '@/api/login/LoginService'

export default {
  components: {
    SIdentify: SIdentify
  },
  data() {
    return {
      loginService: new loginService(), //インターフェース定義
      forget: false,
      flag: true,
      loginForm: {
        // 这是登录表单的数据绑定对象
        mobile: '',
        password: '',
        fp: ''
      },
      inputInfo: '',
      loading: false,
      staffName: '',

      loginFormRules: {
        // 这是表单的验证规则对象
        // mobile: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { min: 11, max: 11, message: '请输入正确格式的手机号', trigger: 'blur' }
        // ],
        mobile: [
          {
            required: true,
            message: 'ユーザー名を入力してください',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: 'パスワードを入力してください', trigger: 'blur' },
          { min: 6, max: 15, message: 'パスワードは6文字から15文字までです', trigger: 'blur' }
        ]
      },
      identifyCode: '', // 验证码初始值
      identifyCodes: '1234567890' // 验证码的随机取值范围
    }
  },
  loginService: null,
  created() {
    this.enterEvent()
  },
  mounted() {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  methods: {
    enterEvent() {
      document.onkeydown = e => {
        let body = document.getElementsByTagName('body')[0]
        if (e.keyCode === 13 && e.target.baseURI.match(/GetLogin/) && e.target === body) {
          // match(此处应填写文件在浏览器中的地址，如 '/home/index')，不写的话，其他页面也会有调用回车按下的方法
          this.login()
        }
      }
    },
    // リセット
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
      this.inputInfo = ''
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    handleLogin() {
      this.$refs.loginFormRef.validate(async valid => {
        if (valid) {
          if (this.inputInfo === '') {
            this.loading = false
            return this.$message.error('キャプチャーを入力してください')
          } else if (this.identifyCode !== this.inputInfo) {
            this.loading = false
            this.$message.error('キャプチャーが間違っています。再入力してください')
            this.loading = false
            return this.changeCode()
          }
          const date = {
            staffId: this.loginForm.mobile,
            password: md5(this.loginForm.password)
          }
          this.loginService.login(date).then(res => {
            setTimeout(() => {
              if (res.data.success == 'true') {
                localStorage.setItem('authToken', res.data.result.authToken)
                localStorage.setItem('currentRole', res.data.result.currentRole)
                localStorage.setItem('merchantId', res.data.result.merchantId)
                this.$router.push('/GetProductsList')
              } else if (res.data.success == 'false') {
                this.$message.error(res.data.message)
              } else {
                this.$message.error('システムエラーが発生しまいました。')
              }
            }, 100)
          })
        }
      })
    },
    // 生成一个随机整数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 刷新验证码
    changeCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 随机生成验证码字符串
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background-image: url(../../../public/img/bg.jpg);
  background-size: 100% 100%;
  // background-color: #001f6f;
  height: 100%;
}

.login_box {
  width: 500px;
  height: 340px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: center;
}
</style>
